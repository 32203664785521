

































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Structure } from "@/api/models/structures/structure";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Agence } from "@/api/models/structures/agence";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import AjoutAgence from "./AjoutAgence.vue"
import UpdateAgence from "./UpdateAgence.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BSpinner,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    AjoutAgence,
    UpdateAgence
  },
})
export default class ListStructures extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  structureSelected: Structure | null = null;
  mainLoading = true;

  isAddNewAgenceSidebarActive = false;
  isUpdateAgenceSidebarActive = false;
  selectedAgenceId = "";


  tableColumns = [
    { key: "libelle", sortable: true, label: "libellé" },
    { key: "actions", sortable: false }
  ];
  paginatedListAgences = new PaginatedList<Agence>();

  async created() {
    this.mainLoading = true;
    await this.$http.myressif.structures
      .getById(this.$route.params.id)
      .then((response: Structure) => {
        this.structureSelected = response;
      });

    await this.loadListAgences({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
    this.mainLoading = false;
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListAgences.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListAgences.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListAgences({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  goToList() {
    this.$router.push({ name: "structures" });
  }

  async loadListAgences(params: any) {
    if (!params || params?.reset) {
      this.paginatedListAgences = new PaginatedList<Agence>();
    }
    await this.$http.myressif.structures
      .getListAgencesByStructureId(this.$route.params.id)
      .then((response: PaginatedList<Agence>) => {
        this.paginatedListAgences = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cette agence ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.structures
      .deleteAgenceStructure(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'une agence",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        await this.loadListAgences({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }

  editAgence(agenceId: string) {
    this.selectedAgenceId = agenceId;
    this.isUpdateAgenceSidebarActive = true;
  }
}
